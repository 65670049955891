<template>
  <div>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Editar Datos de Usuario Administrador"
      size="lg"
      color="primary"
      class="edit-user-modal"
      :show.sync="showModal"
    >
      <CCard v-if="userData"
        style="max-height: 70vh; overflow-y: scroll;">
        <CCardHeader>
          <b>{{userData.name}}</b>
        </CCardHeader>
        <CCardBody>
          <CRow class="d-flex justify-content-center align-items-center">
            <CCol lg="3" class="c-bg-success c-py-3">
              <img
                v-if="userData.info.avatar"
                style="width: 100%; height: auto;"
                :src="userData.info.avatar"
                class="c-mb-2"
              />
              <div class="icon-user" v-else><font-awesome-icon icon="user"/></div>
            </CCol>
            <CCol lg="9" class="c-bg-success c-py-3">
              <CForm ref="formUserUpdate">
                <CInput
                  placeholder="Nombre"
                  required
                  v-model='userUpdate.name'
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  placeholder="Rut"
                  required
                  v-model='userUpdate.rut'
                  @input="changedRut()"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  :class="showAlertEmail ? 'mb-0' : null"
                  placeholder="Email"
                  required
                  v-model='userUpdate.email'
                  @input="changedEmail()"
                >
                  <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                </CInput>
                <div v-show="showAlertEmail" class="text-right mb-1 text-primary" style="font-size: 10px;">
                  Ten en cuenta que al modificar el email, el usuario deberá ingresar con el email que ingreses.
                </div>
                <CInput
                  placeholder="Dirección"
                  required
                  v-model='userUpdate.direccion'
                >
                  <template #prepend-content><font-awesome-icon icon="map-marked-alt" /></template>
                </CInput>
                <CInput
                  placeholder="Teléfono"
                  required
                  v-model='userUpdate.telefono'
                >
                  <template #prepend-content><font-awesome-icon icon="phone" /></template>
                </CInput>
                <CInput
                  placeholder="Contraseña"
                  :required="!user"
                  v-model='userUpdate.password'
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <div v-if="checkPermissions(['isSuperAdmin', 'edit_permission_user_admin'])">
                  <p class="mb-0">Seleccione Cuadrante</p>
                  <v-select
                    :clearable="false"
                    :options="cuadrantes"
                    label="nombre" :reduce="data => data.id" v-model="userUpdate.cuadrante_id"></v-select>
                  <p class="mb-0">Tipo de usuario</p>
                  <v-select
                    :clearable="false"
                    :options="[
                      {id: 0, nombre: 'Administrador'},
                      {id: 9, nombre: 'SuperAdmin'}
                    ]"
                    label="nombre" :reduce="data => data.id" v-model="userUpdate.type" @input="setSelectedType"></v-select>
                </div>
              </CForm>
            </CCol>
          </CRow>
          <CRow class="d-flex justify-content-center align-items-center" v-if="checkPermissions(['edit_permission_user_admin'])">
            <CCol lg="12" class="c-bg-success c-py-3">
              <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                <p class="my-0">Permisos en panel</p>
              </div>
              <div class="d-flex align-items-center mb-2 py-2 px-4" style="border-bottom: 1px solid #E2E2E2;">
                <div class="font-weight-bold mr-4" style="width: 130px">
                  <p class="my-0">Usuarios App</p>
                </div>
                <div class="mx-3 d-flex">
                  <CSwitch
                    class="mr-2"
                    size="sm"
                    color="primary"
                    shape = "pill"
                    :checked.sync="permissions.view_user_app"
                  />
                  <p class="mb-0">Visualizar usuarios</p>
                </div>
                <div class="mx-3 d-flex">
                  <CSwitch
                    class="mr-2"
                    size="sm"
                    color="primary"
                    shape = "pill"
                    :checked.sync="permissions.edit_user_app"
                  />
                  <p class="mb-0">Modificar usuarios</p>
                </div>
              </div>
              <div class="d-flex align-items-center py-2 px-4" style="border-bottom: 1px solid #E2E2E2;">
                <div class="font-weight-bold mr-4" style="width: 130px">
                  <p class="my-0">Usuarios Admin</p>
                </div>
                <div class="mx-3 d-flex">
                  <CSwitch
                    class="mr-2"
                    size="sm"
                    color="primary"
                    shape = "pill"
                    :checked.sync="permissions.view_user_admin"
                  />
                  <p class="mb-0">Visualizar</p>
                </div>
                <div class="mx-3 d-flex">
                  <CSwitch
                    class="mr-2"
                    size="sm"
                    color="primary"
                    shape = "pill"
                    :checked.sync="permissions.create_user_admin"
                  />
                  <p class="mb-0">Crear</p>
                </div>
                <div class="mx-3 d-flex">
                  <CSwitch
                    class="mr-2"
                    size="sm"
                    color="primary"
                    shape = "pill"
                    :checked.sync="permissions.edit_user_admin"
                  />
                  <p class="mb-0">Modificar datos</p>
                </div>
                <div class="mx-3 d-flex">
                  <CSwitch
                    class="mr-2"
                    size="sm"
                    color="primary"
                    shape = "pill"
                    :checked.sync="permissions.edit_permission_user_admin"
                  />
                  <p class="mb-0">Modificar permisos</p>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow class="d-flex justify-content-center align-items-center mb-3" v-if="checkPermissions(['isSuperAdmin', 'edit_permission_user_admin']) && userUpdate.type === 9">
            <CCol lg="12" class="c-bg-success c-py-3">
              <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                <p class="my-0">Permisos en panel (SuperAdmin)</p>
              </div>
              <div class="d-flex align-items-center mb-2 py-2 px-4" style="border-bottom: 1px solid #E2E2E2;">
                <div class="font-weight-bold mr-4" style="width: 130px">
                  <p class="my-0">Cuadrantes</p>
                </div>
                <div class="mx-3 d-flex">
                  <CSwitch
                    class="mr-2"
                    size="sm"
                    color="primary"
                    shape = "pill"
                    :checked.sync="permissions.view_cuadrantes"
                  />
                  <p class="mb-0">Visualizar cuadrantes</p>
                </div>
                <div class="mx-3 d-flex">
                  <CSwitch
                    class="mr-2"
                    size="sm"
                    color="primary"
                    shape = "pill"
                    :checked.sync="permissions.edit_cuadrantes"
                  />
                  <p class="mb-0">Modificar Cuadrantes</p>
                </div>
              </div>
              <div class="d-flex align-items-center py-2 px-4" style="border-bottom: 1px solid #E2E2E2;">
                <div class="font-weight-bold mr-4" style="width: 130px">
                  <p class="my-0">Publicidad</p>
                </div>
                <div class="mx-3 d-flex">
                  <CSwitch
                    class="mr-2"
                    size="sm"
                    color="primary"
                    shape = "pill"
                    :checked.sync="permissions.view_publicidad"
                  />
                  <p class="mb-0">Visualizar publicidad</p>
                </div>
                <div class="mx-3 d-flex">
                  <CSwitch
                    class="mr-2"
                    size="sm"
                    color="primary"
                    shape = "pill"
                    :checked.sync="permissions.edit_publicidad"
                  />
                  <p class="mb-0">Crear publicidad</p>
                </div>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    	<template #footer>
	      <CButton
	        color="primary"
	        size="sm"
	        class="m-1"
	        @click="cerrarModal()"
	      >
	        Cancelar
	      </CButton>
	      <CButton
	        color="success"
	        class="m-1"
	        @click="initUpdateUser()"
	      >
	        {{user ? 'Actualizar':'Crear Usuario'}}
	      </CButton>
    	</template>
    </CModal>
  </div>
</template>

<script>
import { updateAdminUser } from '../../api/users'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { validate, clean, format } from 'rut.js'
import checkPermissions from '../../helpers/userPermissions'

export default {
  name: 'EditAdminUser',
  props: ['showModal', 'user', 'closeModal', 'cuadrantes'],
  components: {'v-select': vSelect},
  data: () => {
    return {
      fieldsGroups: [
        { key: 'name', label: 'Usuario' },
        { key: 'email', label: 'Email' },
      ],
      showAlertEmail: false,
      userData: null,
    	userUpdate: {
    		id: null,
    		name: '',
    		email: '',
        rut: '',
        type: 0,
    		direccion: '',
    		telefono: '',
        cuadrante_id: 0
    	},
      toasts: [],
      permissions: {
        view_user_app: false,
        edit_user_app: false,
        view_user_admin: false,
        create_user_admin: false,
        edit_user_admin: false,
        edit_permission_user_admin: false,
        view_cuadrantes: false,
        edit_cuadrantes: false,
        view_publicidad: false,
        edit_publicidad: false,
      }
    }
  },
  watch:{
  	user: function(){
  		if(this.user){
        this.userData = this.user
		  	this.userUpdate = {
		  		id: this.user.id,
          type: this.user.type,
		  		name: this.user.name,
		  		email: this.user.email,
          rut: format(this.user.info.rut),
		  		direccion: this.user.info.direccion,
		  		telefono: this.user.info.telefono,
          cuadrante_id: this.user.info.cuadrante_id
		  	}
        if(this.user.permisos){
          this.permissions =  this.user.permisos.permissions
        }
        this.showAlertEmail = false
		  }
      else{
        this.userData = {
          name: 'Nuevo Usuario',
          info: {}
        }
        this.userUpdate.type = 0
        this.userUpdate.cuadrante_id = this.$store.state.userData.info.cuadrante_id
      }
  	}
  },
  methods: {
    checkPermissions,
    setSelectedType(data){
      console.log(data)
      this.userUpdate = Object.assign({}, this.userUpdate, { type: data })
      if(data === 0){
        this.permissions =  Object.assign({}, this.permissions, { 
          view_cuadrantes: false,
          edit_cuadrantes: false,
          view_publicidad: false,
          edit_publicidad: false
        })
      }
      console.log(this.userUpdate)
    },
    cerrarModal(){
      this.userUpdate = {
        id: null,
        name: '',
        email: '',
        rut: '',
        direccion: '',
        telefono: '',
        cuadrante_id: 0
      }
      this.permissions = {
        view_user_app: false,
        edit_user_app: false,
        view_user_admin: false,
        create_user_admin: false,
        edit_user_admin: false,
        edit_permission_user_admin: false,
        view_cuadrantes: false,
        edit_cuadrantes: false,
        view_publicidad: false,
        edit_publicidad: false
      }
      this.showAlertEmail = false
      this.closeModal()
    },
    changedRut(){
      console.log(validate(this.userUpdate.rut))
    },
    changedEmail(){
      if(this.user.email !== this.userUpdate.email){
        this.showAlertEmail = true
      }
      else{
        this.showAlertEmail = false
      }
    },
    initUpdateUser(){
      const self = this
      this.userUpdate.rut = format(this.userUpdate.rut)
      if( !validate(this.userUpdate.rut) ){
        this.toasts.push({
          title: 'Rut incorrecto',
          message: 'El rut ingresado no es válido, por favor verifícalo antes de continuar.'
        })
        return false
      }
      this.userUpdate.rut = clean(this.userUpdate.rut)
      this.permissions.edit_user_app ? this.permissions.view_user_app = true : null
      this.permissions.edit_user_admin || this.permissions.edit_permission_user_admin ? this.permissions.view_user_admin = true : null
      this.userUpdate.permissions = this.permissions
      if(this.$refs.formUserUpdate.checkValidity()){
        updateAdminUser(this.userUpdate)
          .then(function(resp){
            console.log(resp)
            self.closeModal()
          })
          .catch(function(error){
            console.log(error)
            if(error.code === 400){
              self.toasts.push({
                title: 'Error',
                message: 'El rut ingresado ya existe para otro usuario en la base de datos, por favor verifique.'
              })
            }
          })
      }
      else{
        this.toasts.push({
          title: 'Todos los campos son obligatorios',
          message: 'Por favor completa todos los campos de datos de usuario.'
        })
      }
    }
  }
}
</script>